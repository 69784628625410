import React from "react";
import { TextField, InputAdornment, Button } from "@mui/material";
import DateFilter from "./DateFilter";

const Search = ({
  globalFilter,
  setGlobalFilter,
  getSearchResult,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const handleSearchClick = (e) => {
    e.preventDefault();
    getSearchResult(globalFilter, startDate, endDate);
  };

  React.useEffect(() => {
    const savedFilter = localStorage.getItem("globalFilter");
    if (savedFilter) {
      setGlobalFilter(savedFilter); // Восстанавливаем значение из localStorage
    }
  }, [setGlobalFilter]);

  // Сохраняем значение globalFilter в localStorage при его изменении
  React.useEffect(() => {
    if (globalFilter !== undefined) {
      localStorage.setItem("globalFilter", globalFilter);
    }
  }, [globalFilter]);

  return (
    <form onSubmit={handleSearchClick}>
      <TextField
        fullWidth
        value={globalFilter}
        onChange={(e) => setGlobalFilter(e.target.value)} // Обновляем globalFilter
        placeholder="Введите название группы или ФИО преподавателя или наименование дисциплины..."
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button variant="contained" color="primary" type="submit">
                Поиск
              </Button>
            </InputAdornment>
          ),
        }}
      />
      <DateFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    </form>
  );
};

export default Search;
